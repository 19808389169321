body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,

  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,

  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  font-size: 1em;
}

.tab code{
  font-family: "Roboto Mono", Consolas, Menlo, Courier, monospace,
    -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  font-size: 12px !important;
}

.tab b{
   margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px !important;
}

mark {
  border-radius: 0.2em;
  background-color: #fafafa;
}
.rs-picker-daterange-menu
{
  z-index: 2000 !important;
}

.css-1k455el{
  background-color: #fafbfd;
}




/* Custom scrollbar styles for webkit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
    width: none; /* Width of the vertical scrollbar */
    height: none; /* Height of the horizontal scrollbar */
}

*::-webkit-scrollbar-track {
    background: none; /* Track background */
}

*::-webkit-scrollbar-thumb {
    background: none; /* Thumb color */
    border-radius: none; /* Thumb border radius */
}

*::-webkit-scrollbar-thumb:hover {
    background: none; /* Thumb hover color */
}

/* Custom scrollbar styles for Firefox */
* {
    scrollbar-width: none; /* Make the scrollbar thin */
    scrollbar-color: none; /* Thumb color and track color */
}
